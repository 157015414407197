<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>게임ID</li>
        <li>{{ item["id"] }}</li>
      </ul>
      <ul>
        <li>제공회사</li>
        <li>{{ item["gameProvider"] }}</li>
      </ul>
      <ul>
        <li>게임타입</li>
        <li>{{ item["gameType"] }}</li>
      </ul>
      <ul>
        <li>테이블정보</li>
        <li>{{ item["table"].name }}</li>
      </ul>
      <template v-for="(uValue, idx) in item['participants']">
        <template v-for="(xValue, i) in uValue['bets']">
          <ul>
            <li>베팅코드({{ i + 1 }})</li>
            <li>{{ xValue.code }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}({{ i + 1 }})</li>
            <li>{{ xValue.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}({{ i + 1 }})</li>
            <li>{{ xValue.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각({{ i + 1 }})</li>
            <li>{{ xValue.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치({{ i + 1 }})</li>
            <li>{{ xValue.description }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디({{ i + 1 }})</li>
            <li>{{ xValue.transactionId }}</li>
          </ul>
        </template>
      </template>
    </div>
    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="item.result">
      <ul v-if="item.result && item.result.banker">
        <li>뱅커 : {{ item["result"].banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + item['result'].banker.cards[0]">{{ item["result"].banker.cards[0] }}</em>
            <em :class="'C' + item['result'].banker.cards[1]">{{ item["result"].banker.cards[1] }}</em>
            <em :class="'C' + item['result'].banker.cards[2]">{{ item["result"].banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.player">
        <li>플레이어 : {{ item["result"].player.score }}</li>
        <li class="data">
          <div class="card">
             <em :class="'C' + item['result'].player.cards[0]">{{ item["result"].player.cards[0] }}</em>
             <em :class="'C' + item['result'].player.cards[1]">{{ item["result"].player.cards[1] }}</em>
             <em :class="'C' + item['result'].player.cards[2]">{{ item["result"].player.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.dealtToPlayer">
        <li>dealtToPlayer</li>
        <li class="data">
          <div class="card">
            <template v-for="(bValue, i) in item.result.dealtToPlayer">
               <em :class="'C' + item['result'].dealtToPlayer[i]">{{ item["result"].dealtToPlayer[i] }}</em>
            </template>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.dealerHand">
        <li>dealerHand : {{ item["result"].dealerHand.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + item['result'].dealerHand.cards[0]">{{ item["result"].dealerHand.cards[0] }}</em>
            <em :class="'C' + item['result'].dealerHand.cards[1]">{{ item["result"].dealerHand.cards[1] }}</em>
            <em :class="'C' + item['result'].dealerHand.cards[2]">{{ item["result"].dealerHand.cards[2] }}</em>
            <em :class="'C' + item['result'].dealerHand.cards[3]">{{ item["result"].dealerHand.cards[3] }}</em>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.lightningPayTable">
        <li>id</li>
        <li>{{ item["result"].lightningPayTable.id }}</li>
      </ul>
      <ul v-if="item.result && item.result.lightningPayTable">
        <li>value</li>
        <li>{{ item["result"].lightningPayTable.value }}</li>
      </ul>
      <ul v-if="item.result && item.result.dragon">
        <li>dragon : {{ item["result"].dragon.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + item['result'].dragon.card">{{ item["result"].dragon.card }}</em>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.tiger">
         <li>tiger : {{ item["result"].tiger.score }}</li>
         <li class="data">
           <div class="card">
             <em :class="'C' + item['result'].tiger.card">{{ item["result"].tiger.card }}</em>
           </div>
         </li>
      </ul>
      <ul v-if="item.result && item.result.aSpot">
         <li>aSpot : {{ item["result"].aSpot.score }}</li>
         <li class="data">
           <div class="card">
             <em :class="'C' + item['result'].aSpot.card">{{ item["result"].aSpot.card }}</em>
           </div>
         </li>
      </ul>
      <ul v-if="item.result && item.result.bSpot">
         <li>bSpot : {{ item["result"].bSpot.score }}</li>
         <li class="data">
           <div class="card">
             <em :class="'C' + item['result'].bSpot.card">{{ item["result"].bSpot.card }}</em>
           </div>
         </li>
      </ul>
      <ul v-if="item.result && item.result.outcome">
        <li>outcome</li>
        <li>{{ item["result"].outcome }}</li>
      </ul>
      <ul v-if="item.result && item.result.buttonsCount">
        <li>buttonsCount</li>
        <li>{{ item["result"].buttonsCount }}</li>
      </ul>
      <ul v-if="item.result && item.result.luckyNumbers">
        <li>luckyNumbers</li>
        <li>{{ item["result"].luckyNumbers }}</li>
      </ul>
      <ul v-if="item.result && item.result.payoutLevel">
        <li>payoutLevel</li>
        <li>{{ item["result"].payoutLevel }}</li>
      </ul>
      <ul v-if="item.result && item.result.reachedMultiplier">
        <li>reachedMultiplier</li>
        <li>{{ item["result"].reachedMultiplier }}</li>
      </ul>
      <ul v-if="item.result && item.result.redEnvelopePayouts">
        <li>redEnvelopePayouts</li>
        <li>{{ item["result"].redEnvelopePayouts }}</li>
      </ul>
      <ul v-if="item.result && item.result.winningNumbers">
        <li>winningNumbers</li>
        <li>{{ item["result"].winningNumbers }}</li>
      </ul>
      <ul v-if="item.result && item.result.first">
        <li>first</li>
        <li>{{ item["result"].first }}</li>
      </ul>
      <ul v-if="item.result && item.result.second">
        <li>second</li>
        <li>{{ item["result"].second }}</li>
      </ul>
      <ul v-if="item.result && item.result.third">
        <li>third</li>
        <li>{{ item["result"].third }}</li>
      </ul>
      <template v-if="item.result.outcomes">
        <template v-for="(yValue, i) in item.result.outcomes">
          <template v-if="item['gameType'] === 'roulette'">
            <ul>
              <li>당첨숫자</li>
              <li>{{yValue.number}}</li>
            </ul>
            <ul>
              <li>홀짝</li>
              <li>{{yValue.type}}</li>
            </ul>
            <ul>
              <li>당첨컬러</li>
              <li>{{yValue.color}}</li>
            </ul>
          </template>
        </template>
      </template>
      <template v-if="item['gameType'] === 'craps'">
        <template v-for="(zValue, i) in item.result.rolls">
          <ul>
            <li>롤ID</li>
            <li>{{zValue.rollId}}</li>
          </ul>
          <ul>
            <li>시작시간</li>
            <li>{{zValue.startedAt}}</li>
          </ul>
          <ul>
            <li>결과1</li>
            <li>{{zValue.result.first}}</li>
          </ul>
          <ul>
            <li>결과2</li>
            <li>{{zValue.result.second}}</li>
          </ul>
        </template>
      </template>
      <template v-if="item['gameType'] === 'freebet'">
        <template v-for="(xValue, i) in item.result.wonSideBets">
          <ul>
            <li>code</li>
            <li>{{xValue.code}}</li>
          </ul>
          <ul>
            <li>combination</li>
            <li>{{xValue.combination}}</li>
          </ul>
        </template>
      </template>
      <template v-if="item['gameType'] === 'cashorcrash'">
        <template v-for="(wValue, i) in item.result.drawnBalls">
          <ul>
            <li>ballType</li>
            <li>{{wValue.ballType}}</li>
          </ul>
          <ul>
            <li>combination</li>
            <li>{{wValue.payoutLevel}}</li>
          </ul>
          <ul>
            <li>reachedMultiplier</li>
            <li>{{wValue.reachedMultiplier}}</li>
          </ul>
        </template>
      </template>
      <ul>
        <li>결과전체</li>
        <li>{{item.result}}</li>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode1',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
